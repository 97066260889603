import React from "react";
import "../../assets/css/main.css";
import "./apply_for_affiliations.css";

function Apply_For_Affiliations() {
  return (
    <>
      <div cl></div>
      <div class="biggest">

      <div class="container magr">
    
    <div class="row align-items-center">

        <div class="col-lg-6 col-md-6 order-2 order-md-1 mt-4 pt-2 mt-sm-0 opt-sm-0">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6 col-6">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 mt-4 pt-2">
                        <h5 class=" pi bridging_the_educational_gap marginup">
                          Eligibility criteria
                        </h5>
                        <div class="  first card work-desk rounded border-0 shadow-lg overflow-hidden p-1">
                          <p class="size">
                            <ul class="Margin11l">
                              <li>Purpose built campus.</li>

                              <li>Proposed building plan in case of land.</li>

                              <li>Resourceful classrooms.</li>

                              <li>Co-& extra-curricular facilities </li>
                            </ul>
                          </p>
                                <div class="img-overlay bg-dark"></div>
                            </div>
                        </div>
                     

                       
                    </div>
                 
                </div>
            

                <div class="col-lg-6 col-md-6 col-6">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                        <h5 class=" bridging_the_educational_gap mgn marginup">What we offer?</h5>
                                <div class=" card work-desk rounded border-0 shadow-lg overflow-hidden p-1" >
                                <p  class="size">

                                <ul class="Margin11l">
                                    <li>Centralized Education management system</li>
                                   
                                    <li>Quality assurance regulations</li>
                                   
                                    <li>We provide our own curriculum</li>
                                    
                                    <li>Policies manuals</li>
                                    
                                    <li>Marketing and promotion in media</li>
                                   
                                    <li>Faculty hiring and training</li>
                                  
                                    <li>Academic and personal development support</li>
                                   
                                   <li>Assessment and promotion system</li>
                                   
                                   <li>360 staff appraisals</li>
                                </ul>

                                </p>
                                <div class="img-overlay bg-dark"></div>
                            </div>
                        </div>
                     

                      
                    
                    </div>
                  
                </div>
              
            </div>
           
        </div>
  

        <div class="col-lg-6 col-md-6 col-12 order- order-md- ">
                <div class="section-title ml-lg-6 bottm ">
                    <h3 class="text-custom font-weight-normal mb-3 bridging_the_educational_gap clrr">Join Our Affilliate Program</h3>
                    <h5 class="title mb-4 adjuss">
                       Join Us to prepare future generations for a constantly changing world<br></br> <br></br>
                       
                    </h5>
                    
                    <img class=" img  addedimg22 img-flui shadow " 
                    src = "/affiliate new 1.jpg"

                     alt="" /> 
                    
                    <img class="addedimg1 img-fluid" 
                    
                    src= "" alt="" />
                    </div>
                    
                            
                </div>
    </div>

</div>




























          <div id="container2" style={{ marginLeft: 10 }}>
            <h3 class="title bridging_the_educational_gap mggt mggt1">
              Pricing Plans
            </h3>
            <div class="block">
              
             
                <h3
                  class="title bridging_the_educational_gap mggt"
                  style={{ textAlign: "left", marginLeft: 9 }}
                >
                  Kindergarten
                </h3>
                <p class="sub-title">
                  <ul class="Margin11">
                    <li> Franchise Fee – 3000 € (one Time)</li>

                    <li>Percentage on Tuition Fee – 10% (each student)</li>

                    <li> Percentage on Admission Fee – 10 % (each student)</li>

                    <li>Annual renewal – 10% of Admission Fee</li>
                  </ul>
                </p>
              </div>
              <div class="block">
                <h3 class="title bridging_the_educational_gap mggt">
                  Primary (Grade1-6)
                </h3>
                <p>
                  <ul class="Margin11">
                    <li> Franchise Fee – 3200 € (one Time)</li>

                    <li>Percentage on Tuition Fee – 10% (each student)</li>

                    <li>Percentage on Admission Fee – 10 % (each student)</li>

                    <li>Annual renewal – 10% of Admission Fee</li>
                  </ul>
                </p>
              </div>
              <div class="block">
                <h3 class="title bridging_the_educational_gap mggt ">
                  Lower Secondary(Grade 7-9)
                </h3>
                <p class="sub-title">
                  <ul class="Margin11">
                    <li> Franchise Fee – 3500 € (one Time) </li>

                    <li> Percentage on Tuition Fee – 10% (each student)</li>

                    <li> Percentage on Admission Fee – 10 % (each student)</li>

                    <li>Annual renewal – 10% of Admission Fee</li>
                  </ul>
                </p>
              </div>
              <div class="block">
                <h3 class="title bridging_the_educational_gap mggt">
                  
                  Upper Secondary(Grade 10-12)
                </h3>
                <p>
                  <ul class="Margin11">
                    <li>Franchise Fee – 3500 € (one Time) </li>

                    <li>Percentage on Tuition Fee – 10% (each student)</li>

                    <li>Percentage on Admission Fee – 10 % (each student)</li>

                    <li>Annual renewal – 10% of Admission Fee</li>
                  </ul>
                </p>
              </div>
            </div>

            {/* <br><br> */}
            <section class="minus">
              <div class="row">
                <div class="col-md-12 pb-20 text-center">
                  <h2 class="mb-10 kkl bridging_the_educational_gap">
                    How to apply for affiliation and start up
                  </h2>
                  <p class="section-sub-title">
                    Following steps can help you to understand how to join
                  </p>
                  <div class="exp-separator center-separator">
                    <div class="exp-separator-inner"></div>
                  </div>
                </div>
              </div>

              <br></br>

              <div class="cardss">
                <div class=" card1">
                  1. Submission of Expression of Interest
                </div>
                <div class=" card1">
                  2. Presentation & Discussion of Business Plan
                </div>
                <div class=" card1">3. Financial Viability Analysis</div>
                <div class=" card1">4. Submission of Application.</div>
                <div class="card1">5. On-site visit by the Team</div>
                <div class=" card1">6. Suitability Report Analysis</div>
                <div class="card1">7. Signing of MOU</div>
                <div class="card1">8. Admissions Campaign</div>
                <div class=" card1">9. Orientation on Operation Manuals</div>
                <div class=" card1">10. Leadership training</div>
                <div class=" card1">11. Staff training</div>
              </div>
            </section>
          </div>
        
      
        
        
    </>
  );
}

export default Apply_For_Affiliations;
