import wpService from "../Services/blogs_api";
import About from "../components/About/about";
import Benefit from "../components/Benefit/benefit";
import Front_Page from "../components/Header/front_page";
import Header from "../components/Header/header";
import Rating from "../components/Rating/Rating";
import Testimonial from "../components/Testimonial/testimonial";
import Blog from "../components/blog/blog";
// import BlogItem from "../components/blog/blogItem";
import BlogItem from "../components/blog/blogItem"
import BlogsSidebar from "../components/blog/blogsidebar";
import Footer from "../components/footer/footer";
import React, { useState, useEffect } from 'react';
import News from "../components/news/news";
import Offer from "../components/offer/offer";
import Support from "../components/support/support";
import BlogSilder from "../components/blog/blogslider";
import LogoSlider from "../components/partners/logos";

 function Home() {

    // const posts = await wpService.getLatestPosts({ count: 8 });

    const [posts, setPosts] = useState([]);

    useEffect(() => {
      const fetchPosts = async () => {
        try {
          const latestPosts = await wpService.getLatestPosts({ count: 8 });
          setPosts(latestPosts);
        } catch (error) {
          console.error('Error fetching posts:', error);
        }
      };
  
      fetchPosts();
    }, []);
    return (
<>
    <Header />
    <Front_Page/>
    <Rating />
    <About />
    <LogoSlider/>
    <Offer />
    <Benefit />
    <Testimonial />
    <Support />
    <Blog />        
    <News />
    <Footer />

</>
    )
}

export default Home;