import React, { useEffect, useState } from 'react';
import './ContactUs.css'; // Import your CSS file here
import emailjs from "@emailjs/browser";

function ContactUs() {
  const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  const phoneRegex = /^[0-9\+]{1,13}$/
  const [formData, setFormData] = useState({
    FirstName: '',
    LastName: '',
    Email: '',
    PhoneNumber: '',
    message: '',
  });
  const serviceId = "service_z85plgc";
  const templateId = "template_xz4yqbm";
  useEffect(() => emailjs.init("TYL7nW3MIEQGmLnQt"), []);
  const [formError, setFormError] = useState('');
  const handleSubmit = async (event) => {
    event.preventDefault();
    
    // Check if any required fields are missing
    if (!formData.FirstName || !formData.LastName || !formData.Email || !formData.message) {
      setFormError('Please fill out all required fields.');
    } else if (!emailRegex.test(formData.Email)) {
      setFormError('Please enter a valid email address.');
    } else if (!phoneRegex.test(formData.PhoneNumber)) {
      setFormError('Please enter a valid phone number (numbers and + only, max 13 characters).');
    } else {

      await emailjs.send(serviceId, templateId, {
        to_name: "Edu-Linx Admin",
        from_name: formData?.FirstName + '  ' + formData?.LastName,
        email: formData?.Email, // Add individual fields like this
        phone: formData?.PhoneNumber, // Add more fields if needed
        message : formData?.message
         
       });
      // Form is complete and format is valid, you can submit the data or show a success message
    setFormData({FirstName: '',
    LastName: '',
    Email: '',
    PhoneNumber: '',
    message: '',})
      setFormError('');
      window.alert('Submitted successfully. Our representative will get back to you shortly.');
    
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  return (
    <div className="contact_us_2">
      <div className="responsive-container-block big-container">
        <div className="blueBG">
        <div className="breacrumb-cover bg-img-about">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6">
                                    {/* <img  
                                        src=
                                        {require("../../assets/images/img-about.png")} alt="joxBox" /> */}
                                        <h2 className="mb-10">
                                          {/* About Us */}
                                          </h2>
                                        <p className="font-lg color-text-paragraph-2">
                                          {/* Get the latest news, updates and tips */}
                                          
                                          
                                          
                                          </p>
                                    </div>
                                    <div className="col-lg-6 text-lg-end">
                                        <ul className="breadcrumbs mt-40">
                                            <li>
                                                <a className="home-icon" href="#">
                                                    {/* Home */}
                                                </a>
                                            </li>
                                            <li>
                                              {/* About Us */}
                                              </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
        {/* <div className="col-lg-6 col-md-12 col-sm-12" 
        id="img-responsive">
                                        <img  
                                        src=
                                        {require("../../assets/images/img-about.png")} alt="joxBox" />
                                    </div> */}
        </div>
        <div className="responsive-container-block container">
          <form className="form-box">
            <div className="container-block form-wrapper">
              <p className="text-blk contactus-head">
                Get in Touch
              </p>
              <p className="text-blk contactus-subhead">
               Drop Us your Query, and We will get back to you as soon as possible.
              </p>
              <div className="responsive-container-block">
                <div className="responsive-cell-block wk-ipadp-6 wk-tab-12 wk-mobile-12 wk-desk-6" id="i10mt">
                  <p className="text-blk input-title">
                    FIRST NAME
                  </p>
                  <input className="input" id="ijowk" name="FirstName" placeholder="Please enter first name..." 
                  onChange = {handleInputChange}
                  value = {formData.FirstName}/>
                </div>
                <div className="responsive-cell-block wk-desk-6 wk-ipadp-6 wk-tab-12 wk-mobile-12">
                  <p className="text-blk input-title">
                    LAST NAME
                  </p>
                  <input className="input" id="indfi" name="LastName" placeholder="Please enter last name..."
                  onChange = {handleInputChange}
                  value = {formData.LastName} />
                </div>
                <div className="responsive-cell-block wk-desk-6 wk-ipadp-6 wk-tab-12 wk-mobile-12">
                  <p className="text-blk input-title">
                    EMAIL
                  </p>
                  <input className="input" id="ipmgh" name="Email" placeholder="Please enter email..."
                  onChange = {handleInputChange} 
                  value = {formData.Email}/>
                </div>
                <div className="responsive-cell-block wk-desk-6 wk-ipadp-6 wk-tab-12 wk-mobile-12">
                  <p className="text-blk input-title">
                    PHONE NUMBER
                  </p>
                  <input className="input" id="imgis" name="PhoneNumber" placeholder="Please enter phone number..."
                  onChange = {handleInputChange}
                  value = {formData.PhoneNumber} />
                </div>
                <div className="responsive-cell-block wk-tab-12 wk-mobile-12 wk-desk-12 wk-ipadp-12" id="i634i">
                  <p className="text-blk input-title">
                   Message
                  </p>
                  <textarea className="textinput" id="i5vyy"  name = "message" placeholder="Please enter query..."
                  onChange = {handleInputChange}
                  value = {formData.message}></textarea>
                </div>
              </div>
              <button className="submit-btn" onClick={handleSubmit}>
                Submit
              </button>
              {formError && <p className="error">{formError}</p>}
            </div>
            {/* <div className="social-media-links">
              <a href="#" id="ix94i-2">
                <img className="link-img" src="https://workik-widget-assets.s3.amazonaws.com/Footer1-83/v1/images/Icon-twitter.png" alt="Twitter" />
              </a>
              <a href="#">
                <img className="link-img" src="https://workik-widget-assets.s3.amazonaws.com/Footer1-83/v1/images/Icon-facebook.png" alt="Facebook" />
              </a>
              <a href="#">
                <img className="link-img" src="https://workik-widget-assets.s3.amazonaws.com/Footer1-83/v1/images/Icon-google.png" alt="Google" />
              </a>
              <a href="#" id="izldf-2">
                <img className="link-img" src="https://workik-widget-assets.s3.amazonaws.com/Footer1-83/v1/images/Icon-instagram.png" alt="Instagram" />
              </a>
            </div> */}
          </form>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
