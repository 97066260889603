import React from 'react';
import './terms_and_conditions.css'
const TermsAndConditionsPage = () => {
  return (
    <div className="terms-and-conditions">
      <h1 className="page-title">Terms and Conditions</h1>

      <div className="content">
        <h2>1. Acceptance of Terms</h2>
        <p>
          By using the Edu-Linx website, you agree to comply with and be bound by these terms and conditions, which
          govern your use of this website. If you disagree with any part of these terms and conditions, please do not use
          our website.
        </p>

        <h2>2. Use of Website</h2>
        <p>
          You agree to use the Edu-Linx website for lawful purposes only and in a manner that does not infringe the
          rights of, restrict, or inhibit the use and enjoyment of this website by any third party.
        </p>

        <h2>3. User Account</h2>
        <p>
          You may be required to create an account to access certain features of the website. You are responsible for
          maintaining the confidentiality of your account and password and for restricting access to your computer. You
          agree to accept responsibility for all activities that occur under your account.
        </p>

        <h2>4. Content</h2>
        <p>
          All content on the Edu-Linx website, including text, graphics, images, and audio, is provided for
          informational purposes only. You may not modify, reproduce, or distribute any content without prior written
          consent.
        </p>

        <h2>5. Termination</h2>
        <p>
          Edu-Linx reserves the right to terminate or suspend your account and access to the website at its sole
          discretion and without notice for any violation of these terms and conditions or for any other reason.
        </p>

        {/* Add more sections for terms and conditions content as needed */}
      </div>
    </div>
  );
};

export default TermsAndConditionsPage;
