import React, { useEffect } from 'react';
import "./blogMainPage.css"
import CategoriesSidebar from './categoriesSidebar';
import BlogCategoryPage from './blogCategoryPage';
import { useParams } from 'react-router-dom/dist';
function BlogCategoryMainPage() {

    const { category , id } = useParams();
    useEffect(() => {
      console.log("category inside" , category)

    }, [id , category])
    console.log("category" , category)
    
  return (
    <div className="mainLayout">
      < BlogCategoryPage 
      categoryId = {id}/>
      <CategoriesSidebar />
    </div>
  );
}

export default BlogCategoryMainPage;
