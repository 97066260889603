import React from 'react'
import "../../assets/css/main.css";
import "./support.css";

function Support() {
  return (
    <>
            <section class="support__section">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-xl-6 col-lg-6 col-12">
                        <div class="support__according-wrapper">

                            <h3 class="according__heading">We support institutions to solve following basic key
                                questions;</h3>

                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne" aria-expanded="true"
                                            aria-controls="collapseOne">
                                            How well school’s curriculum meets learners’ needs?
                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse show"
                                        data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                    We meticulously tailors its curriculum to ensure it precisely meets learners' needs, fostering success through personalized and effective educational experiences ...
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                            aria-expanded="false" aria-controls="collapseTwo">
                                            How good are learners in their personal, social and emotional developments?
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" class="accordion-collapse collapse"
                                        data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                        Our platform prioritizes holistic success, ensuring learners' excellence not only in academics but also in personal, social, and emotional development, ensuring well-rounded achievement ...
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseThree"
                                            aria-expanded="false" aria-controls="collapseThree">
                                            How well are the learners’ attaining and progressing in core subjects?
                                        </button>
                                    </h2>
                                    <div id="collapseThree" class="accordion-collapse collapse"
                                        data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                        We diligently monitors and ensures learners' success, assessing their proficiency and advancement in core subjects to enable continuous improvement and academic excellence. ...
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseFour"
                                            aria-expanded="false" aria-controls="collapseFour">
                                            How Important Is Community and Collaboration for Learners?
                                        </button>
                                    </h2>
                                    <div id="collapseFour" class="accordion-collapse collapse"
                                        data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                       We foster collaboration through forums, group projects, and discussion boards, allowing you to share ideas, seek help, and build a network of like-minded peers ...
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6  col-12 support__image-wrapper">
                        <img src={require('../../assets/images/suport image.png')} alt="" class="img-fluid w-100 support__img" />
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Support