import React from "react";
import "../../assets/css/main.css";
import "./blog.css";
import { useState, useEffect } from 'react';
import wpService from "../../Services/blogs_api";
// import { Link } from "react-router-dom/dist";
import { Link } from 'react-router-dom';
const blogData = [
  {
    img: "blog-img.png",
    category: "Learning",
    heading: "Quality Education",
    date: "21, feb 2023",
    time: "3 min read",
  },
  {
    img: "blog-img.png",
    category: "Learning",
    heading: "Power of AI technologies",
    date: "21, feb 2023",
    time: "3 min read",
  },
  {
    img: "blog-img.png",
    category: "Learning",
    heading: "Awards and Recognitions",
    date: "21, feb 2023",
    time: "3 min read",
  },
  {
    img: "blog-img.png",
    category: "Learning",
    heading: "Rigorous Curriculum",
    date: "21, feb 2023",
    time: "3 min read",
  },
];

function Blog() {

  const [posts, setPosts] = useState([]);
  const [displayImg, setDisplayImg] = useState(true);
  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const latestPosts = await wpService.getLatestPosts({ count: 8 });
        setPosts(latestPosts);
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };

    fetchPosts();
  }, []);
  const handleImageError = (currentTarget) => {
    currentTarget.onerror = null; // prevents looping
    setDisplayImg(false);
    currentTarget.remove();
  };
  const get_blog_post_date = (blog)=>{

    const todayDate = new Date(blog["date"]);
  const day = todayDate.getDate();

  const year = todayDate.getFullYear();

  var month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ][todayDate.getMonth()];
  const postDate = day + " " + month + " " + year;
  return postDate;

  }
  
  return (
    <>  
      <div class="blog__sectionM" id="events">
        <p class="section__title">blogs & article</p>
        <p class="section__text">take a look at the latest articles</p>

        <div class="blog__section-wrapperM ">
          <div class="container-fluid blog__card-area p-0 pt-2">
            <div class="blog__cards-wrapper">



              {posts.map((blog, index) => {
                return (
                  <>
                  <Link to={`/blogss/${blog.id}`} key={index}>
                    <article class="blog__cardM">
                      <div class="blog__card-img-wrapper">
                      {displayImg && (
              <img
                src={
                  blog["_embedded"]["wp:featuredmedia"]
                    ? blog["_embedded"]["wp:featuredmedia"]?.[0]["source_url"]
                    : "/avatar.png"
                }
                className="object-cover object-center"
                onError={({ currentTarget }) => handleImageError(currentTarget)}
                // width={600}
                // height={280}
                alt={blog["title"]["rendered"]}
                // style={{
                //   objectFit: "cover",
                //   borderRadius: "8px",
                // }}
              />
            )}
            {!displayImg && (
            <div className="flex border-2 text-2xl font-semibold justify-center items-center w-full h-full bg-skin-primary-white">
              No Preview
            </div>
          )}
                        {/* <img src="assets/images/blog-img.png" class="blog__card-img" alt="" /> */}
                      </div>
                      <div class="blog__card-data">
                        {/* <div class="blog__card-category">{'val.category'}</div> */}
                        <p class="blog__card-heading">{blog["title"]["rendered"]?.length > 30 ? blog["title"]["rendered"]?.slice(0,30) + '...' : blog["title"]["rendered"] }</p>
                        
                        <div class="blog__card-bottom-bar">
                          <div class="blog__card-bottom-item">
                            <svg
                              class="blog__card-icon"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 16 17"
                              fill="none"
                            >
                              <path
                                d="M14 2.1H11.6V0.9C11.6 0.793913 11.5579 0.692172 11.4828 0.617157C11.4078 0.542143 11.3061 0.5 11.2 0.5C11.0939 0.5 10.9922 0.542143 10.9172 0.617157C10.8421 0.692172 10.8 0.793913 10.8 0.9V2.1H5.2V0.9C5.2 0.793913 5.15786 0.692172 5.08284 0.617157C5.00783 0.542143 4.90609 0.5 4.8 0.5C4.69391 0.5 4.59217 0.542143 4.51716 0.617157C4.44214 0.692172 4.4 0.793913 4.4 0.9V2.1H2C1.46976 2.10064 0.961423 2.31155 0.586488 2.68649C0.211553 3.06142 0.000635143 3.56976 0 4.1V14.5C0.000635143 15.0302 0.211553 15.5386 0.586488 15.9135C0.961423 16.2884 1.46976 16.4994 2 16.5H14C14.5303 16.4996 15.0388 16.2887 15.4137 15.9137C15.7887 15.5388 15.9996 15.0303 16 14.5V4.1C15.9996 3.5697 15.7887 3.06124 15.4137 2.68625C15.0388 2.31127 14.5303 2.10042 14 2.1ZM15.2 14.5C15.2 14.8183 15.0736 15.1235 14.8485 15.3485C14.6235 15.5736 14.3183 15.7 14 15.7H2C1.68174 15.7 1.37652 15.5736 1.15147 15.3485C0.926428 15.1235 0.8 14.8183 0.8 14.5V7.7H15.2V14.5ZM15.2 6.9H0.8V4.1C0.8 3.4376 1.336 2.9 2 2.9H4.4V4.1C4.4 4.20609 4.44214 4.30783 4.51716 4.38284C4.59217 4.45786 4.69391 4.5 4.8 4.5C4.90609 4.5 5.00783 4.45786 5.08284 4.38284C5.15786 4.30783 5.2 4.20609 5.2 4.1V2.9H10.8V4.1C10.8 4.20609 10.8421 4.30783 10.9172 4.38284C10.9922 4.45786 11.0939 4.5 11.2 4.5C11.3061 4.5 11.4078 4.45786 11.4828 4.38284C11.5579 4.30783 11.6 4.20609 11.6 4.1V2.9H14C14.3183 2.9 14.6235 3.02643 14.8485 3.25147C15.0736 3.47652 15.2 3.78174 15.2 4.1V6.9Z"
                                fill="#626262"
                              />
                              <rect
                                x="9.60001"
                                y="9.30005"
                                width="4.8"
                                height="4.8"
                                rx="1"
                                fill="#626262"
                              />
                            </svg>

                            <p class="blog__card-bottom-text">{get_blog_post_date(blog)}</p>
                          </div>
                          {/* <div class="blog__card-bottom-item">
                            <svg
                              class="blog__card-icon"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 17 17"
                              fill="none"
                            >
                              <path
                                d="M8.56915 0.5C12.9876 0.5 16.5692 4.0816 16.5692 8.5C16.5692 12.9184 12.9876 16.5 8.56915 16.5C4.15075 16.5 0.569153 12.9184 0.569153 8.5C0.569153 4.0816 4.15075 0.5 8.56915 0.5ZM8.56915 2.1C6.87177 2.1 5.2439 2.77428 4.04367 3.97452C2.84344 5.17475 2.16915 6.80261 2.16915 8.5C2.16915 10.1974 2.84344 11.8253 4.04367 13.0255C5.2439 14.2257 6.87177 14.9 8.56915 14.9C10.2665 14.9 11.8944 14.2257 13.0946 13.0255C14.2949 11.8253 14.9692 10.1974 14.9692 8.5C14.9692 6.80261 14.2949 5.17475 13.0946 3.97452C11.8944 2.77428 10.2665 2.1 8.56915 2.1ZM8.56915 3.7C8.7651 3.70003 8.95422 3.77196 9.10065 3.90217C9.24708 4.03238 9.34063 4.2118 9.36355 4.4064L9.36915 4.5V8.1688L11.5348 10.3344C11.6782 10.4784 11.7615 10.6715 11.7677 10.8747C11.7739 11.0779 11.7026 11.2758 11.5682 11.4282C11.4337 11.5807 11.2463 11.6762 11.044 11.6955C10.8416 11.7148 10.6395 11.6563 10.4788 11.532L10.4036 11.4656L8.00355 9.0656C7.87922 8.94116 7.79936 8.7792 7.77635 8.6048L7.76915 8.5V4.5C7.76915 4.28783 7.85344 4.08434 8.00347 3.93431C8.1535 3.78429 8.35698 3.7 8.56915 3.7Z"
                                fill="#565656"
                              />
                            </svg>

                            <p class="blog__card-bottom-text">{val.time}</p>
                          </div> */}
                        </div>
                      </div>
                    </article>
                    </Link>

                  </>
                );
              })}


            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Blog;
