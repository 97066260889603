// import React from "react";
// import "./FUSN.css"; // Custom styling file for additional design

// const FUSNPage = () => {
//   return (
//     <div className="fusn-container">
//       {/* Hero Section */}
//       <header className="hero-section">
//         <div className="overlay">
//           <h1>Future Unified Schools Network (FUSN)</h1>
//           <p>Transforming Education for a Brighter Future</p>
//         </div>
//       </header>

//       {/* About Section */}
//       <section className="about-section container">
//         <h2>About FUSN</h2>
//         <p>
//         The Future Unified Schools Network (FUSN) represents a bold and innovative approach to schooling in Pakistan. It is founded on the belief that education must not only impart knowledge but also nurture character, skills, and a deep sense of purpose. The vision of FUSN is to produce well-rounded Muslim scholars who are not only grounded in their faith and cultural identity but also possess the competence and skills to excel in diverse fields such as computer engineering, business, medicine, and beyond. These scholars will be equipped to thrive in a rapidly changing world, contributing to society as ethical leaders, critical thinkers, and problem-solvers.
//         </p>
//       </section>

//       {/* Why FUSN Section */}
//       <section className="why-fusn-section container">
//         <h2>Why FUSN?</h2>
//         <div className="row">
//           {[
//             {
//               icon: "fas fa-book",
//               title: "Holistic Development",
//               text: "Balancing Islamic values, moral ethics, and modern education."
//             },
//             {
//               icon: "fas fa-tools",
//               title: "Skill-Based Learning",
//               text: "Equipping students with practical, marketable skills."
//             },
//             {
//               icon: "fas fa-flag",
//               title: "Cultural Pride & Patriotism",
//               text: "Instilling a deep sense of national identity and love for Pakistan."
//             },
//             {
//               icon: "fas fa-briefcase",
//               title: "Employability & Empowerment",
//               text: "Preparing students to earn while still in school."
//             }
//           ].map((item, index) => (
//             <div key={index} className="col-md-6 feature-box">
//               <i className={item.icon}></i>
//               <h4>{item.title}</h4>
//               <p>{item.text}</p>
//             </div>
//           ))}
//         </div>
//       </section>

//       {/* Our Unique Approach */}
//       <section className="approach-section container">
//         <h2>Our Unique Approach</h2>
//         <div className="row">
//           {[
//             { title: "Personalized Skill Development", icon: "fas fa-user-graduate" },
//             { title: "Tailored Learning Plans", icon: "fas fa-chart-line" },
//             { title: "Quranic Education & Arabic", icon: "fas fa-quran" },
//             { title: "Paperless School Operations", icon: "fas fa-laptop" },
//             { title: "Quality Assurance", icon: "fas fa-check-circle" },
//             { title: "Balanced Curriculum", icon: "fas fa-book-reader" },
//             { title: "Earn While You Learn", icon: "fas fa-money-bill-wave" }
//           ].map((item, index) => (
//             <div key={index} className="col-md-4 approach-box">
//               <i className={item.icon}></i>
//               <h4>{item.title}</h4>
//             </div>
//           ))}
//         </div>
//       </section>

//       {/* Vision Section */}
//       <section className="vision-section container">
//         <h2>Our Vision</h2>
//         <p>
//           FUSN envisions a generation of ethical leaders, skilled professionals,
//           and critical thinkers who contribute meaningfully to society.
//         </p>
//       </section>

//       {/* Affiliate Section */}
//       <section className="affiliate-section container">
//         <h2>Benefits of Affiliating with FUSN</h2>
//         <ul>
//           <li>Adopt a proven educational model.</li>
//           <li>Enhance your institution’s reputation.</li>
//           <li>Access cutting-edge tools and technology.</li>
//           <li>Attract more students and parents.</li>
//           <li>Contribute to national progress.</li>
//         </ul>
//       </section>

//       {/* Contact Section */}
//       <section className="contact-section">
//         <div className="overlay">
//           <h2>Join the FUSN Network</h2>
//           <p>
//             Be part of this transformative movement. Contact us to affiliate
//             with FUSN.
//           </p>
//           <div className="contact-details">
//             <p>Email: info@edu-linx.com</p>
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// };

// export default FUSNPage;




import "./FUSN.css"; // Ensure to create this CSS file
import KeyPillars from "./key_pillers";
import HowWeWork from "./HowWeWork";
import React, { useEffect, useState } from "react";
import emailjs from "@emailjs/browser";
import LogoSlider from "../partners/logos";

const FUSNPage = () => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  const phoneRegex = /^[0-9\+]{1,13}$/
    const [formData, setFormData] = useState({
        FirstName: '',
        LastName: '',
        Email: '',
        PhoneNumber: '',
        message: '',
      });
      const serviceId = "service_z85plgc";
      const templateId = "template_xz4yqbm";
      useEffect(() => emailjs.init("TYL7nW3MIEQGmLnQt"), []);
       const [formError, setFormError] = useState('');
       const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      };
    
      const handleSubmit = async (event) => {
        event.preventDefault();
        
        // Check if any required fields are missing
        if (!formData.FirstName || !formData.LastName || !formData.Email || !formData.message) {
          setFormError('Please fill out all required fields.');
        } else if (!emailRegex.test(formData.Email)) {
          setFormError('Please enter a valid email address.');
        } else if (!phoneRegex.test(formData.PhoneNumber)) {
          setFormError('Please enter a valid phone number (numbers and + only, max 13 characters).');
        } else {
    
          await emailjs.send(serviceId, templateId, {
            to_name: "Edu-Linx Admin",
            from_name: formData?.FirstName + '  ' + formData?.LastName,
            email: formData?.Email, // Add individual fields like this
            phone: formData?.PhoneNumber, // Add more fields if needed
            message : formData?.message
             
           });
          // Form is complete and format is valid, you can submit the data or show a success message
        setFormData({FirstName: '',
        LastName: '',
        Email: '',
        PhoneNumber: '',
        message: '',})
          setFormError('');
          window.alert('Submitted successfully. Our representative will get back to you shortly.');
        
        }
      };

    //   const handleSubmit = (e) => {
    //     e.preventDefault();
    //     console.log("Form Submitted:", formData);
    //     alert("Your query has been submitted. Our representative will get back to you soon.");
    //   };
  return (
    <div className="fusn-container">
      {/* Hero Section */}
      <section className="hero-section">
        <div className="overlay">
          <h1>Future Unified Schools Network</h1>
          <p>Transforming Education for a Brighter Future</p>
        </div>
      </section>

      {/* About FUSN */}
      <section className="about-section container">
        <h2><i className="fas fa-school"></i> About FUSN</h2>
        <p>
        The Future Unified Schools Network (FUSN) represents a bold and innovative approach to schooling in Pakistan. It is founded on the belief that education must not only impart knowledge but also nurture character, skills, and a deep sense of purpose. The vision of FUSN is to produce well-rounded Muslim scholars who are not only grounded in their faith and cultural identity but also possess the competence and skills to excel in diverse fields such as computer engineering, business, medicine, and beyond. These scholars will be equipped to thrive in a rapidly changing world, contributing to society as ethical leaders, critical thinkers, and problem-solvers
        </p>
      </section>

      {/* Why FUSN */}
      <section className="why-fusn-section container">
        <h2><i className="fas fa-lightbulb"></i> Why FUSN?</h2>
        <div className="row">
          {[
            {
              icon: "fas fa-book-reader",
              title: "Holistic Education",
              text: "Balancing Islamic values with modern education."
            },
            {
              icon: "fas fa-tools",
              title: "Skill-Based Learning",
              text: "Teaching practical skills like coding, robotics, and financial literacy."
            },
            {
              icon: "fas fa-flag",
              title: "Cultural Pride & Patriotism",
              text: "Instilling a strong national identity and love for Pakistan."
            },
            {
              icon: "fas fa-briefcase",
              title: "Employability & Empowerment",
              text: "Preparing students for financial independence."
            }
          ].map((item, index) => (
            <div key={index} className="col-md-6 feature-box">
              <i className={item.icon}></i>
              <h4>{item.title}</h4>
              <p>{item.text}</p>
            </div>
          ))}
        </div>
      </section>

      {/* FUSN Vision */}
      {/* <section className="vision-section container">
        <h2><i className="fas fa-eye"></i> Our Vision</h2>
        <p>
          We aim to produce ethical leaders, skilled professionals, and
          critical thinkers who contribute meaningfully to society.
        </p>
      </section> */}

<KeyPillars />
<HowWeWork/>
      {/* Our Approach */}
      {/* <section className="approach-section container">
        <h2><i className="fas fa-cogs"></i> Our Unique Approach</h2>
        <div className="row">
          {[
            { title: "Personalized Skill Development", icon: "fas fa-user-graduate" },
            { title: "Tailored Learning Plans", icon: "fas fa-chart-line" },
            { title: "Quranic Education & Arabic", icon: "fas fa-quran" },
            { title: "Paperless School Operations", icon: "fas fa-laptop" },
            { title: "Quality Assurance", icon: "fas fa-check-circle" },
            { title: "Balanced Curriculum", icon: "fas fa-book-open" },
            { title: "Earn While You Learn", icon: "fas fa-money-bill-wave" }
          ].map((item, index) => (
            <div key={index} className="col-md-4 approach-box">
              <i className={item.icon}></i>
              <h4>{item.title}</h4>
            </div>
          ))}
        </div>
      </section> */}

      {/* Affiliate Section */}
      {/* <section className="affiliate-section container">
        <h2><i className="fas fa-handshake"></i> Benefits of Affiliating</h2>
        <ul>
          <li>Adopt a proven educational model.</li>
          <li>Enhance your institution’s reputation.</li>
          <li>Access cutting-edge tools and technology.</li>
          <li>Attract more students and parents.</li>
          <li>Contribute to national progress.</li>
        </ul>
      </section> */}

      {/* School Affiliation Form */}
      {/* <section className="affiliation-form container">
        <h2><i className="fas fa-file-signature"></i> School Affiliation Form</h2>
        <form>
          <div className="row">
            <div className="col-md-6">
              <label>School Name:</label>
              <input type="text" className="form-control" />
            </div>
            <div className="col-md-6">
              <label>Primary Contact Person:</label>
              <input type="text" className="form-control" />
            </div>
            <div className="col-md-6">
              <label>Phone Number:</label>
              <input type="text" className="form-control" />
            </div>
            <div className="col-md-6">
              <label>Email:</label>
              <input type="email" className="form-control" />
            </div>
          </div>
          <button type="submit" className="btn btn-primary mt-3">
            Submit Application
          </button>
        </form>
      </section> */}

      {/* Contact Section */}
      {/* <section className="contact-section">
        <div className="overlay">
          <h2>Join the FUSN Network</h2>
          <p>Be part of this transformative movement.</p>
          <p> For any Query Drop us an Email: info@edu-linx.com</p>
          <p>Phone: +92 300 1234567</p>
          <p>Website: www.fusn.edu.pk</p>
        </div>
      </section> */}


<LogoSlider/>
    <section className="contact-section">
      <div className="contact-container">
        {/* Left Side: Contact Details */}
        <div className="contact-info">
          <h2>Join the FUSN Network</h2>
          <p>Be part of this transformative movement.</p>
          <p>Send us an email at <strong>info@edu-linx.com</strong> or fill out the form.</p>
          <p>Our representative will get back to you soon.</p>
        </div>

        {/* Right Side: Contact Form */}
        <div className="contact-form-container">
          <form onSubmit={handleSubmit} className="contact-form">
            <div className="form-group">
              <label htmlFor="FirstName">First Name</label>
              <input
                type="text"
                id="firstName"
                name="FirstName"
                value={formData.FirstName}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="LastName">Last Name</label>
              <input
                type="text"
                id="lastName"
                name="LastName"
                value={formData.LastName}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="Email"
                value={formData.Email}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="phoneNumber">Phone Number</label>
              <input
                type="tel"
                id="phoneNumber"
                name="PhoneNumber"
                value={formData.PhoneNumber}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                name="message"
                rows="4"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
            </div>

            <button type="submit" className="submit-btn">Send Message</button>
            {formError && <p className="error">{formError}</p>}
          </form>
        </div>
      </div>
    </section>
    </div>
  );
};

export default FUSNPage;
