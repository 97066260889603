import React from 'react'
import './header.css'
import { useNavigate } from 'react-router-dom';
import { Link , useLocation} from 'react-router-dom/dist';
import { NavLink } from 'react-router-dom';



function Header() {

    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate('/affiliations');
    };
    const handleFUSNButtonClick = () => {
        navigate('/fusn');
    };
    const location = useLocation();
  return (
    <>
    <header className="header">

<div className="container-fluid top-navbar px-0 px-sm-2 px-md-3 px-lg-5 px-xl-5">
    <div className="container top-nav-data pt-3">
        <p className="top-nav-mail">info@edu-linx.com</p>
    </div>

    <div className="top-nav-buttons">

        <a href="https://www.facebook.com/edulinx78690" className="top-nav-button">
            <svg xmlns="http://www.w3.org/2000/svg" className='top-nav-button-icon' width="15" height="14"
                viewBox="0 0 15 14" fill="none">
                <path
                    d="M7 13C6.21207 13 5.43185 12.8448 4.7039 12.5433C3.97595 12.2417 3.31451 11.7998 2.75736 11.2426C2.20021 10.6855 1.75825 10.0241 1.45672 9.2961C1.15519 8.56815 1 7.78793 1 7C1 6.21207 1.15519 5.43185 1.45672 4.7039C1.75825 3.97595 2.20021 3.31451 2.75736 2.75736C3.31451 2.20021 3.97595 1.75825 4.7039 1.45672C5.43185 1.15519 6.21207 1 7 1C8.5913 1 10.1174 1.63214 11.2426 2.75736C12.3679 3.88258 13 5.4087 13 7C13 8.5913 12.3679 10.1174 11.2426 11.2426C10.1174 12.3679 8.5913 13 7 13ZM7 13V6.14286C7 5.6882 7.18061 5.25216 7.5021 4.93067C7.82359 4.60918 8.25963 4.42857 8.71429 4.42857H9.14286M4.85714 7.85714H9.14286"
                    stroke="#66B7D1" />
            </svg>
        </a>

        <a href='https://www.linkedin.com/company/edulinx78690/' className="top-nav-button">
            <svg xmlns="http://www.w3.org/2000/svg" className='top-nav-button-icon' width="16" height="14"
                viewBox="0 0 16 14" fill="none">
                <path
                    d="M9.82857 8.14857C10.4574 7.50606 11.2761 7.08571 12.2286 7.08571C13.2288 7.08571 14.1881 7.48306 14.8954 8.19034C15.6027 8.89762 16 9.8569 16 10.8571V16H14.6286V10.8571C14.6286 10.2206 14.3757 9.61017 13.9256 9.16009C13.4755 8.71 12.8651 8.45714 12.2286 8.45714C11.5921 8.45714 10.9816 8.71 10.5315 9.16009C10.0814 9.61017 9.82857 10.2206 9.82857 10.8571V16H8.45714V7.42857H9.82857V8.14857ZM5.02857 6.05714C4.75578 6.05714 4.49416 5.94878 4.30126 5.75588C4.10837 5.56299 4 5.30137 4 5.02857C4 4.75578 4.10837 4.49416 4.30126 4.30126C4.49416 4.10837 4.75578 4 5.02857 4C5.30137 4 5.56299 4.10837 5.75588 4.30126C5.94878 4.49416 6.05714 4.75578 6.05714 5.02857C6.05714 5.30137 5.94878 5.56299 5.75588 5.75588C5.56299 5.94878 5.30137 6.05714 5.02857 6.05714ZM4.34286 7.42857H5.71429V16H4.34286V7.42857Z" 
                    stroke="#66B7D1" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </a>

        <a href='https://www.youtube.com/@edulinx78690' className="top-nav-button">
        <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="20" height="15" rx="4" fill="white"/>
<path d="M6.07687 16H15.923" stroke="#66B7D1" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18.1795 4H3.82051C3.36736 4 3 4.35817 3 4.8V13.6C3 14.0418 3.36736 14.4 3.82051 14.4H18.1795C18.6326 14.4 19 14.0418 19 13.6V4.8C19 4.35817 18.6326 4 18.1795 4Z" stroke="#66B7D1" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13.455 9.33167L9 7V11.6633L13.455 9.33167Z" stroke="#66B7D1" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
        </a>

        <a href='https://www.instagram.com/edulinx78690' className="top-nav-button">
            <svg xmlns="http://www.w3.org/2000/svg" className='top-nav-button-icon' width="14" height="14"
                viewBox="0 0 14 14" fill="none">
                <path
                    d="M10 3.57143H10.8571M4.42857 1H9.57143C10.4807 1 11.3528 1.36122 11.9958 2.00421C12.6388 2.64719 13 3.51926 13 4.42857V9.57143C13 10.4807 12.6388 11.3528 11.9958 11.9958C11.3528 12.6388 10.4807 13 9.57143 13H4.42857C3.51926 13 2.64719 12.6388 2.00421 11.9958C1.36122 11.3528 1 10.4807 1 9.57143V4.42857C1 3.51926 1.36122 2.64719 2.00421 2.00421C2.64719 1.36122 3.51926 1 4.42857 1ZM7 9.57143C6.31801 9.57143 5.66396 9.30051 5.18173 8.81827C4.69949 8.33604 4.42857 7.68199 4.42857 7C4.42857 6.31801 4.69949 5.66396 5.18173 5.18173C5.66396 4.69949 6.31801 4.42857 7 4.42857C7.68199 4.42857 8.33604 4.69949 8.81827 5.18173C9.30051 5.66396 9.57143 6.31801 9.57143 7C9.57143 7.68199 9.30051 8.33604 8.81827 8.81827C8.33604 9.30051 7.68199 9.57143 7 9.57143Z"
                    stroke="#66B7D1" />
            </svg>
        </a>

    </div>
</div>




{/* <!-- place navbar here --> */}
<nav className="navbar navbar-expand-xl ">
    <div className="container-fluid navbar-contaiener ">
        <a className="navbar-brand" href="#">
            <img src={require('../../assets/images/logo.png')} className='nav-logo' alt="" />
        </a>
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
    aria-expanded="false" aria-label="Toggle navigation">
    <Link to="/" className="navbar-toggler-icon"></Link>
</button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <NavLink className="nav-link" activeClassName="active" exact to="/">
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" activeClassName="active" to="/about-us">
                About Us
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" activeClassName="active" to="/services">
                Services
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" activeClassName="active" to="/why-choose-us">
                Why Choose Us
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" activeClassName="active" to="/faqs">
                FAQs
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" activeClassName="active" to="/contact-us">
                Contact Us
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" activeClassName="active" to="/blogs/categories/education/3">
                Blogs
              </NavLink>
            </li>
            {/* <li className="nav-item">
              <NavLink className="nav-link" activeClassName="active" to="/blogs/categories/education/3">
                Our Products
              </NavLink>
            </li> */}
            <li className="nav-item dropdown">
  <a className="nav-link dropdown-toggle" href="#" id="productsDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
    Our Products
  </a>
  <ul className="dropdown-menu" aria-labelledby="productsDropdown">
    <li>
      <a className="dropdown-item" href="https://evaluate-web.bh.edu-linx.com/" target="_blank" rel="noopener noreferrer">
        EValuate
      </a>
    </li>
    {/* Add more products here if needed */}
  </ul>
</li>


            </ul>
            <div className="nav-buttons-container">
  <form className="d-flex nav-buttons" role="search">
    <button className="btn nav-btn custom-btn" type="submit" onClick={handleButtonClick}>
      Join as Affiliate
    </button>
  </form>

  <form className="d-flex nav-buttons" role="search">
    <button className="btn nav-btn custom-btn" type="submit" onClick={handleFUSNButtonClick}>
      FUSN School System
    </button>
  </form>
</div>

        </div>
    </div>
</nav>

</header>
    </>
  )
}

export default Header