import React from "react";
import "../../assets/css/main.css";
import "./rating.css";

const ratingData = [
  {
    icon: "M24.1842 38.3947H0.5V31.2895C0.5 24.8947 13.0526 21.8158 19.4474 21.8158C23 21.8158 28.6842 22.7632 32.9474 24.8947C31.0526 25.6053 29.6316 26.5526 28.2105 27.7368C25.6053 26.7895 22.5263 26.3158 19.4474 26.3158C12.3421 26.3158 5 29.8684 5 31.2895V33.8947H24.6579C24.4211 34.8421 24.1842 36.0263 24.1842 37.2105V38.3947ZM45.5 37.2105C45.5 41.7105 41.7105 45.5 37.2105 45.5C32.7105 45.5 28.9211 41.7105 28.9211 37.2105C28.9211 32.7105 32.7105 28.9211 37.2105 28.9211C41.7105 28.9211 45.5 32.7105 45.5 37.2105ZM19.4474 5.23684C22.0526 5.23684 24.1842 7.36842 24.1842 9.97368C24.1842 12.5789 22.0526 14.7105 19.4474 14.7105C16.8421 14.7105 14.7105 12.5789 14.7105 9.97368C14.7105 7.36842 16.8421 5.23684 19.4474 5.23684ZM19.4474 0.5C14.2368 0.5 9.97368 4.76316 9.97368 9.97368C9.97368 15.1842 14.2368 19.4474 19.4474 19.4474C24.6579 19.4474 28.9211 15.1842 28.9211 9.97368C28.9211 4.76316 24.6579 0.5 19.4474 0.5Z",
    number: "40+",
    title: "Worked with Schools",
  },
  {
    icon: "M24 37.2C29.592 37.2 34.32 33.696 36.264 28.8H11.736C13.656 33.696 18.408 37.2 24 37.2ZM15.6 21.6C16.5548 21.6 17.4705 21.2207 18.1456 20.5456C18.8207 19.8705 19.2 18.9548 19.2 18C19.2 17.0452 18.8207 16.1295 18.1456 15.4544C17.4705 14.7793 16.5548 14.4 15.6 14.4C14.6452 14.4 13.7295 14.7793 13.0544 15.4544C12.3793 16.1295 12 17.0452 12 18C12 18.9548 12.3793 19.8705 13.0544 20.5456C13.7295 21.2207 14.6452 21.6 15.6 21.6ZM32.4 21.6C33.3548 21.6 34.2705 21.2207 34.9456 20.5456C35.6207 19.8705 36 18.9548 36 18C36 17.0452 35.6207 16.1295 34.9456 15.4544C34.2705 14.7793 33.3548 14.4 32.4 14.4C31.4452 14.4 30.5295 14.7793 29.8544 15.4544C29.1793 16.1295 28.8 17.0452 28.8 18C28.8 18.9548 29.1793 19.8705 29.8544 20.5456C30.5295 21.2207 31.4452 21.6 32.4 21.6ZM24 43.2C18.9078 43.2 14.0242 41.1772 10.4236 37.5765C6.82285 33.9758 4.8 29.0922 4.8 24C4.8 18.9078 6.82285 14.0242 10.4236 10.4236C14.0242 6.82285 18.9078 4.8 24 4.8C29.0922 4.8 33.9758 6.82285 37.5765 10.4236C41.1772 14.0242 43.2 18.9078 43.2 24C43.2 29.0922 41.1772 33.9758 37.5765 37.5765C33.9758 41.1772 29.0922 43.2 24 43.2ZM24 0C10.728 0 0 10.8 0 24C0 30.3652 2.52856 36.4697 7.02944 40.9706C9.25804 43.1992 11.9038 44.967 14.8156 46.1731C17.7274 47.3792 20.8483 48 24 48C30.3652 48 36.4697 45.4714 40.9706 40.9706C45.4714 36.4697 48 30.3652 48 24C48 20.8483 47.3792 17.7274 46.1731 14.8156C44.967 11.9038 43.1992 9.25804 40.9706 7.02944C38.742 4.80083 36.0962 3.033 33.1844 1.82689C30.2726 0.620779 27.1517 0 24 0Z",
    number: "300+",
    title: "Projects Completed",
  },
  {
    icon: "M5.8 0C2.581 0 0 2.24842 0 5.05263V30.3158C0 31.6558 0.611069 32.941 1.69878 33.8885C2.78649 34.8361 4.26174 35.3684 5.8 35.3684H29V48L37.7 40.4211L46.4 48V35.3684H52.2C53.7383 35.3684 55.2135 34.8361 56.3012 33.8885C57.3889 32.941 58 31.6558 58 30.3158V12.6316V7.57895V5.05263C58 3.71259 57.3889 2.42743 56.3012 1.47988C55.2135 0.532329 53.7383 0 52.2 0H40.6H5.8ZM29 5.05263L37.7 10.1053L46.4 5.05263V13.8947L55.1 17.6842L46.4 21.4737V30.3158L37.7 25.2632L29 30.3158V21.4737L20.3 17.6842L29 13.8947V5.05263ZM5.8 5.05263H20.3V10.1053H5.8V5.05263ZM5.8 15.1579H14.5V20.2105H5.8V15.1579ZM5.8 25.2632H20.3V30.3158H5.8V25.2632Z",
    number: "150+",
    title: "Happy Clients",
  },
  {
    icon: "M53.8359 24.7273L58 28.8291L38.5774 48L28.2564 37.8182L32.4205 33.7164L38.5774 39.7673L53.8359 24.7273ZM20.8205 37.8182L29.7436 46.5455H0V40.7273C0 34.2982 10.6482 29.0909 23.7949 29.0909L29.4164 29.4109L20.8205 37.8182ZM23.7949 0C26.9503 0 29.9764 1.22597 32.2076 3.40821C34.4388 5.59045 35.6923 8.55021 35.6923 11.6364C35.6923 14.7225 34.4388 17.6823 32.2076 19.8645C29.9764 22.0468 26.9503 23.2727 23.7949 23.2727C20.6395 23.2727 17.6133 22.0468 15.3821 19.8645C13.1509 17.6823 11.8974 14.7225 11.8974 11.6364C11.8974 8.55021 13.1509 5.59045 15.3821 3.40821C17.6133 1.22597 20.6395 0 23.7949 0Z",
    number: "9+",
    title: "Countries Presence",
  },
];

function Card(props) {
  return (
    <>
      <div className=" rating__card">
        <div className="rating__badge">
          <svg
            className="rating__icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 46 46"
            fill="none"
          >
            <path d={props.icon} fill="#2B6E85" />
          </svg>
        </div>
        <div className="rating__data">
          <p className="rating__number">{props.number}</p>
          <p className="rating__title">{props.title}</p>
        </div>
      </div>
    </>
  );
}

function Rating() {
  return (
    <>
      <section className="rating__section">
        <div className=" rating">
          <div className="rating__wrapper">
            {ratingData.map((val) => {
              return (
                <Card icon={val.icon} number={val.number} title={val.title} />
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
}

export default Rating;
