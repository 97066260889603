import React from 'react'
import "../../assets/css/main.css";
import "./footer.css";
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <>
            <footer class="footer__section">
        <div class="container-fluid footer__container">

            <div class="row">

                <div class=" col-xl-4 col-lg-4 col-md-6 col-12 footer__content">
                    {/* <a  href='#'> */}

                    <Link   to="/">


                    
                    <img src={require('../../assets/images/footer-logo.png')} class="footer__logo w-50" alt="" />
                    </Link>
                    <p class="footer__text">Edulinx is an educational consultancy firm created to reduce the educational disparity between students in developed and developing countries, both at school and university level. </p>
                    <div class="footer__social">
                    <a href="https://www.facebook.com/edulinx78690">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="20" height="20" rx="4" fill="#2A7086"/>
<path d="M10 16C9.21207 16 8.43185 15.8448 7.7039 15.5433C6.97595 15.2417 6.31451 14.7998 5.75736 14.2426C5.20021 13.6855 4.75825 13.0241 4.45672 12.2961C4.15519 11.5681 4 10.7879 4 10C4 9.21207 4.15519 8.43185 4.45672 7.7039C4.75825 6.97595 5.20021 6.31451 5.75736 5.75736C6.31451 5.20021 6.97595 4.75825 7.7039 4.45672C8.43185 4.15519 9.21207 4 10 4C11.5913 4 13.1174 4.63214 14.2426 5.75736C15.3679 6.88258 16 8.4087 16 10C16 11.5913 15.3679 13.1174 14.2426 14.2426C13.1174 15.3679 11.5913 16 10 16ZM10 16V9.14286C10 8.6882 10.1806 8.25216 10.5021 7.93067C10.8236 7.60918 11.2596 7.42857 11.7143 7.42857H12.1429M7.85714 10.8571H12.1429" stroke="white"/>
</svg>
</a>
<a href="https://www.youtube.com/@edulinx78690">
                        <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="22" height="20" rx="4" fill="#2A7086"/>
<path d="M6.0769 16H15.9231" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18.1795 4H3.82051C3.36736 4 3 4.35817 3 4.8V13.6C3 14.0418 3.36736 14.4 3.82051 14.4H18.1795C18.6326 14.4 19 14.0418 19 13.6V4.8C19 4.35817 18.6326 4 18.1795 4Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13.455 9.33167L9 7V11.6633L13.455 9.33167Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</a>
<a href="https://www.linkedin.com/company/edulinx78690/">
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="20" height="20" rx="4" fill="#2A7086"/>
<path d="M9.82857 8.14857C10.4574 7.50606 11.2761 7.08571 12.2286 7.08571C13.2288 7.08571 14.1881 7.48306 14.8954 8.19034C15.6027 8.89762 16 9.8569 16 10.8571V16H14.6286V10.8571C14.6286 10.2206 14.3757 9.61017 13.9256 9.16009C13.4755 8.71 12.8651 8.45714 12.2286 8.45714C11.5921 8.45714 10.9816 8.71 10.5315 9.16009C10.0814 9.61017 9.82857 10.2206 9.82857 10.8571V16H8.45714V7.42857H9.82857V8.14857ZM5.02857 6.05714C4.75578 6.05714 4.49416 5.94878 4.30126 5.75588C4.10837 5.56299 4 5.30137 4 5.02857C4 4.75578 4.10837 4.49416 4.30126 4.30126C4.49416 4.10837 4.75578 4 5.02857 4C5.30137 4 5.56299 4.10837 5.75588 4.30126C5.94878 4.49416 6.05714 4.75578 6.05714 5.02857C6.05714 5.30137 5.94878 5.56299 5.75588 5.75588C5.56299 5.94878 5.30137 6.05714 5.02857 6.05714ZM4.34286 7.42857H5.71429V16H4.34286V7.42857Z" fill="white"/>
</svg>
</a>

<a href="https://www.instagram.com/edulinx78690">
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="20" height="20" rx="4" fill="#2A7086"/>
<path d="M13 6.57143H13.8571M7.42857 4H12.5714C13.4807 4 14.3528 4.36122 14.9958 5.00421C15.6388 5.64719 16 6.51926 16 7.42857V12.5714C16 13.4807 15.6388 14.3528 14.9958 14.9958C14.3528 15.6388 13.4807 16 12.5714 16H7.42857C6.51926 16 5.64719 15.6388 5.00421 14.9958C4.36122 14.3528 4 13.4807 4 12.5714V7.42857C4 6.51926 4.36122 5.64719 5.00421 5.00421C5.64719 4.36122 6.51926 4 7.42857 4ZM10 12.5714C9.31801 12.5714 8.66396 12.3005 8.18173 11.8183C7.69949 11.336 7.42857 10.682 7.42857 10C7.42857 9.31801 7.69949 8.66396 8.18173 8.18173C8.66396 7.69949 9.31801 7.42857 10 7.42857C10.682 7.42857 11.336 7.69949 11.8183 8.18173C12.3005 8.66396 12.5714 9.31801 12.5714 10C12.5714 10.682 12.3005 11.336 11.8183 11.8183C11.336 12.3005 10.682 12.5714 10 12.5714Z" stroke="white"/>
</svg>
</a>

                    </div>
                </div>

                <div class="col-xl-2 col-lg-2 col-md-3 col-6 footer__content">
                    <p class="footer__heading">About</p>
                    <div class="footer__links">
                    <Link  class="footer__link" to="/about-us">Our Mission</Link>
                    <Link  class="footer__link" to="/about-us">Our Vision</Link>
                    <Link  class="footer__link" to="/about-us">Our Core Values</Link>

                        {/* <a href="#about" class="footer__link">Our Mission</a>
                        <a href="#about" class="footer__link">Our Vision</a>
                        <a href="#about" class="footer__link">Our Core Values</a> */}
                        {/* <a href="#" class="footer__link">Lorem ipsum</a>
                        <a href="#" class="footer__link">Lorem ipsum</a> */}
                    </div>
                </div>

                <div class="col-xl-2 col-lg-2 col-md-3 col-6 footer__content">
                    <p class="footer__heading">Contact</p>
                    <div class="footer__links">
                        <a href="#" class="footer__link">info@edu-linx.com</a>
                        {/* <a href="#" class="footer__link">+97-334469078</a> */}
                        <Link  class="footer__link" to="/contact-us">Contact Form</Link>
                        {/* <a href="#" class="footer__link">Linkedin</a>
                        <a href="#" class="footer__link">Facebook</a>
                        <a href="#" class="footer__link">Instagram</a> */}
                    </div>
                </div>

                <div class="col-xl-2 col-lg-2 col-md-3 col-6 footer__content">
                    <p class="footer__heading">Links</p>
                    <div class="footer__links">
                    <Link  class="footer__link" to="/privacy-policy">Privacy Policy</Link>
                    <Link  class="footer__link" to="/terms_and_conditions">Terms & Conditions</Link>
                    <Link  class="footer__link" to="/cookie-policy">Cookie Policy</Link>
                        {/* <a href="#" class="footer__link">Privacy Policy</a>
                        <a href="#" class="footer__link">Terms & Conditions</a>
                        <a href="#" class="footer__link">Cookie Policy</a> */}
                        {/* <a href="#" class="footer__link">Lorem ipsum</a> */}
                    </div>
                </div>

                <div class="col-xl-2 col-lg-2 col-md-3 col-6 footer__content">
                    <p class="footer__heading">Support</p>
                    <div class="footer__links">
                        <a href="#" class="footer__link">24/7 Availability</a>
                        <Link  class="footer__link" to="/faqs">FAQs</Link>
                        {/* <a href="#" >FAQs</a> */}
                        {/* <a href="#" class="footer__link">Lorem ipsum</a>
                        <a href="#" class="footer__link">Lorem ipsum</a> */}
                    </div>
                </div>



            </div>

        </div>
    </footer>
    </>
  )
}

export default Footer