import React, { useState } from 'react';
import Slider from 'react-slick';
import logo2 from '../../assets/images/partner-logo2.png';
import logo3 from '../../assets/images/partner-logo3.png';
import logo4 from '../../assets/images/partner-logo4.png';
import logo5 from '../../assets/images/partner-logo5.png';
// import logo6 from '../../assets/images/partner-logo6.png';
import './LogoSlider.css'; // <-- Add this line

const LogoSlider = () => {
  const [settings] = useState({
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: 'linear',
  });

  const logos = [logo2, logo3, logo4, logo5];

  return (
    <div className="logo-slider-wrapper">
      <h2 className="logo-slider-heading">Our Partners</h2>
      <Slider {...settings}>
        {logos.map((logo, index) => (
          <div key={index} className="logo-slide">
            <img src={logo} alt={`logo-${index}`} className="logo-image" />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default LogoSlider;
