import React from "react";
import "./HowWeWork.css";

const stepsData = [
  {
    icon: "fas fa-user-cog",
    title: "Personalized Skill Development",
    description: "Partnering with Ulipsu to identify each child's innate talents and align skills with future trends.",
  },
  {
    icon: "fas fa-tasks",
    title: "Tailored Learning Plans",
    description: "Comprehensive ability tests by Energia help create customized learning plans for every student.",
  },
  {
    icon: "fas fa-quran",
    title: "Quranic Education & Arabic Proficiency",
    description: "Every child completes Hifz by Grade 5 and can deliver sermons in Arabic and English by Grade 12.",
  },
  {
    icon: "fas fa-laptop",
    title: "Paperless School Operations",
    description: "EDAP ERP ensures streamlined academics, attendance, and parent-teacher communication.",
  },
  {
    icon: "fas fa-check-circle",
    title: "Quality Assurance with EValuate",
    description: "A mobile app to regulate and ensure high education standards across all schools.",
  },
  {
    icon: "fas fa-book-open",
    title: "Balanced Curriculum",
    description: "Integrating religious, academic, and skill-based education for holistic student development.",
  },
  {
    icon: "fas fa-money-bill-wave",
    title: "Earn While You Learn",
    description: "Students master marketable skills, enabling them to generate income while still in school.",
  },
  {
    icon: "fas fa-chalkboard-teacher",
    title: "Teacher Training & Development",
    description: "Empowering educators with modern teaching methodologies, ensuring high-quality instruction for students.",
  },
  {
    icon: "fas fa-user-friends",
    title: "Student Mentorship & Counseling",
    description: "Providing students with career guidance, mentorship, and emotional support for overall success.",
  },
];

const HowWeWork = () => {
  return (
    <section className="how-we-work-section">
      <div className="container">
        <h2 className="section-title">How We Work: A Transformative Approach</h2>
        <p className="section-description">
          We are redefining education by combining <strong>innovation, personalization, and moral development</strong> to help students reach their full potential.
        </p>

        <div className="steps-grid">
          {stepsData.map((step, index) => (
            <div key={index} className="step-card">
              <div className="icon-container">
                <i className={`${step.icon} step-icon`}></i>
              </div>
              <div className="step-content">
                <h4>{step.title}</h4>
                <p>{step.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default HowWeWork;
