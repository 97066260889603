import React from 'react';
import "./blogMainPage.css"
import CategoriesSidebar from './categoriesSidebar';
import BlogDetail from './dynamicBlog';
function BlogPage() {
  return (
    <div className="mainLayout">
      < BlogDetail/>
      <CategoriesSidebar />
    </div>
  );
}

export default BlogPage;
