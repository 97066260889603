import React from "react";
import "../../assets/css/main.css";
import "./blogCategoryPage.css";
import { useState, useEffect } from 'react';
import wpService from "../../Services/blogs_api";
import { Link } from 'react-router-dom';

function BlogCategoryPage({categoryId}) {
    // const { category , categoryId } = useParams();
  const [posts, setPosts] = useState([]);
  const [displayImg, setDisplayImg] = useState(true);
  useEffect(()=>{

  }, [categoryId])
  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const latestPosts = await wpService.getPostsByCategoryId(categoryId);
        console.log("data post" , latestPosts)
        setPosts(latestPosts);
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };

    fetchPosts();
  }, [categoryId]);
  console.log("blogssss", posts)
  const handleImageError = (currentTarget) => {
    currentTarget.onerror = null; // prevents looping
    setDisplayImg(false);
    currentTarget.remove();
  };
  const get_blog_post_date = (blog)=>{

    const todayDate = new Date(blog["date"]);
  const day = todayDate.getDate();
  const year = todayDate.getFullYear();
  var month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ][todayDate.getMonth()];
  const postDate = day + " " + month + " " + year;
  return postDate;

  }
  
  return (
    <>

    <div class="MainDiv">
      <div class="blog__section" id="events">
        <p class="section__title">blogs & article</p>
        <p class="section__text">take a look at the latest articles</p>

        <div class="blog__section-wrapper">
          <div class="container-fluid blog__card-area p-0 pt-2">
            <div class="blog__cards-wrapper">



              {posts.map((blog, index) => {
                return (
                  <>
                  <Link to={`/blogss/${blog.id}`} key={index}>
                    <article class="blog__card">
                      <div class="blog__card-img-wrapper1">
                      {displayImg && (
              <img class="image-Blog"
                src={
                  blog["_embedded"]["wp:featuredmedia"]
                    ? blog["_embedded"]["wp:featuredmedia"]?.[0]["source_url"]
                    : "/avatar.png"
                }
                className="object-cover object-center"
                onError={({ currentTarget }) => handleImageError(currentTarget)}
               
                alt={blog["title"]["rendered"]}
                // style={{
                //   objectFit: "cover",
                //   borderRadius: "8px",
                // }}
              />
            )}
            {!displayImg && (
            <div className="flex border-2 text-2xl font-semibold justify-center items-center w-full h-full bg-skin-primary-white">
              No Preview
            </div>
          )}
                        {/* <img src="assets/images/blog-img.png" class="blog__card-img" alt="" /> */}
                      </div>
                      <div class="blog__card-data">
                        {/* <div class="blog__card-category">{'val.category'}</div> */}
                        <p class="blog__card-heading">{blog["title"]["rendered"] }</p>
                        {/* <p class="blog__card-heading">{blog["content"]["rendered"]?.length > 30 ? blog["content"]["rendered"]?.slice(0,30) + '...' : blog["content"]["rendered"] }</p> */}
                        <div
                className="text-ellipsis line-clamp-5  overflow-hidden text-left  text-[#6B6B6B] text-sm  sm:text-justify xl:text-left xl:text-[16px] xl:font-normal"
                dangerouslySetInnerHTML={{
                  __html: blog["excerpt"]["rendered"],
                }}
              ></div>
                        <div class="blog__card-bottom-bar">
                          <div class="blog__card-bottom-item">
                            <svg
                              class="blog__card-icon"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 16 17"
                              fill="none"
                            >
                              <path
                                d="M14 2.1H11.6V0.9C11.6 0.793913 11.5579 0.692172 11.4828 0.617157C11.4078 0.542143 11.3061 0.5 11.2 0.5C11.0939 0.5 10.9922 0.542143 10.9172 0.617157C10.8421 0.692172 10.8 0.793913 10.8 0.9V2.1H5.2V0.9C5.2 0.793913 5.15786 0.692172 5.08284 0.617157C5.00783 0.542143 4.90609 0.5 4.8 0.5C4.69391 0.5 4.59217 0.542143 4.51716 0.617157C4.44214 0.692172 4.4 0.793913 4.4 0.9V2.1H2C1.46976 2.10064 0.961423 2.31155 0.586488 2.68649C0.211553 3.06142 0.000635143 3.56976 0 4.1V14.5C0.000635143 15.0302 0.211553 15.5386 0.586488 15.9135C0.961423 16.2884 1.46976 16.4994 2 16.5H14C14.5303 16.4996 15.0388 16.2887 15.4137 15.9137C15.7887 15.5388 15.9996 15.0303 16 14.5V4.1C15.9996 3.5697 15.7887 3.06124 15.4137 2.68625C15.0388 2.31127 14.5303 2.10042 14 2.1ZM15.2 14.5C15.2 14.8183 15.0736 15.1235 14.8485 15.3485C14.6235 15.5736 14.3183 15.7 14 15.7H2C1.68174 15.7 1.37652 15.5736 1.15147 15.3485C0.926428 15.1235 0.8 14.8183 0.8 14.5V7.7H15.2V14.5ZM15.2 6.9H0.8V4.1C0.8 3.4376 1.336 2.9 2 2.9H4.4V4.1C4.4 4.20609 4.44214 4.30783 4.51716 4.38284C4.59217 4.45786 4.69391 4.5 4.8 4.5C4.90609 4.5 5.00783 4.45786 5.08284 4.38284C5.15786 4.30783 5.2 4.20609 5.2 4.1V2.9H10.8V4.1C10.8 4.20609 10.8421 4.30783 10.9172 4.38284C10.9922 4.45786 11.0939 4.5 11.2 4.5C11.3061 4.5 11.4078 4.45786 11.4828 4.38284C11.5579 4.30783 11.6 4.20609 11.6 4.1V2.9H14C14.3183 2.9 14.6235 3.02643 14.8485 3.25147C15.0736 3.47652 15.2 3.78174 15.2 4.1V6.9Z"
                                fill="#626262"
                              />
                              <rect
                                x="9.60001"
                                y="9.30005"
                                width="4.8"
                                height="4.8"
                                rx="1"
                                fill="#626262"
                              />
                            </svg>

                            <p class="blog__card-bottom-text">{get_blog_post_date(blog)}</p>
                          </div>
                        </div>
                      </div>
                    </article>
                    </Link>

                  </>
                );
              })}


            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
}

export default BlogCategoryPage;
