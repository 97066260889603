
// import "../../assets/css/main.css";
// import "./blogs_pages.css";
// import React, { useEffect, useState } from 'react';
// import axios from 'axios';

// const blogData = [
//   {
//     img: "blog-img.png",
//     category: "Learning",
//     heading: "Quality Education",
//     date: "21, feb 2023",
//     time: "3 min read",
//   },
//   {
//     img: "blog-img.png",
//     category: "Learning",
//     heading: "Power of AI technologies",
//     date: "21, feb 2023",
//     time: "3 min read",
//   },
//   {
//     img: "blog-img.png",
//     category: "Learning",
//     heading: "Awards and Recognitions",
//     date: "21, feb 2023",
//     time: "3 min read",
//   },
//   {
//     img: "blog-img.png",
//     category: "Learning",
//     heading: "Rigorous Curriculum",
//     date: "21, feb 2023",
//     time: "3 min read",
//   },
// ];

// function Blogs() {
//   const [posts, setPosts] = useState([]);

//   console.log("postss " , posts)
//   useEffect(() => {
//     axios.get('https://dev-edulinx-blogs.pantheonsite.io/wp-json/wp/v2/posts')
//       .then(response => {
//         setPosts(response.data);
//       })
//       .catch(error => {
//         console.error('Error fetching posts:', error);
//       });
//   }, []);
//   return (
//     <div>
//       <h1>WordPress Posts</h1>
//       <ul>
//         {posts.map(post => (
//           <li key={post.id}>{post.title.rendered} </li>
//         ))}
//       </ul>
//     </div>
//   );
// }

// export default Blogs;


import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'; // Import the Link component for navigation
import axios from 'axios';

function Blogs() {
  const [posts, setPosts] = useState([]);
  const [blogData, setblogData] = useState([]);
  

  
  const handleButtonClick = (id) => {

    console.log("id..." , id)
    axios.get(`https:\/\/dev-edulinx-blogs.pantheonsite.io\/?p=${id}`)
      .then(response => {
        setblogData(response.data);

        return response.data;

      })
      .catch(error => {
        console.error('Error fetching posts:', error);
      });
};
  useEffect(() => {
    axios.get('https://dev-edulinx-blogs.pantheonsite.io/wp-json/wp/v2/posts')
      .then(response => {

        console.log("response..." , response)
        setPosts(response.data);
      })
      .catch(error => {
        console.error('Error fetching posts:', error);
      });
  }, []);

  return (
    <div>
    <h1>WordPress Posts</h1>
    <ul>
      {posts.map(post => (
        <li key={post.id}>
          {/* Use a <div> element as a clickable container */}
          <Link onClick={() => handleButtonClick(post.id)}>
            <h2>{post.title.rendered}</h2>
            {post.featured_media && (
              <img
                src={post.featured_media.source_url}
                alt={post.title.rendered}
              />
            )}
            <p>{post.excerpt.rendered}</p>
          </Link>
        </li>
      ))}
    </ul>
  </div>
  );
}

export default Blogs;
