import React from "react";
import "../../assets/css/main.css";
import "./key_pillers.css"; // Custom CSS file for styling

const pillarsData = [
  {
    icon: "fas fa-balance-scale",
    heading: "Holistic Education",
    text: "FUSN emphasizes a balanced curriculum integrating Islamic values, moral ethics, and modern education, ensuring students develop into ethical individuals.",
  },
  {
    icon: "fas fa-laptop-code",
    heading: "Skill-Based Learning",
    text: "Students master practical skills such as coding, robotics, machine learning, and financial literacy, making education relevant to real-world needs.",
  },
  {
    icon: "fas fa-brain",
    heading: "Critical Thinking & Problem-Solving",
    text: "FUSN fosters a culture of inquiry, encouraging students to question assumptions and develop creative solutions to complex challenges.",
  },
  {
    icon: "fas fa-flag",
    heading: "Cultural Pride & Patriotism",
    text: "Students develop a strong national identity and a deep appreciation for Pakistan’s heritage, ensuring commitment to national progress.",
  },
  {
    icon: "fas fa-user-graduate",
    heading: "Diverse & Versatile Scholars",
    text: "FUSN prepares students to excel in religious and secular fields while staying grounded in their faith and values.",
  },
  {
    icon: "fas fa-briefcase",
    heading: "Empowerment & Employability",
    text: "By providing marketable skills and ethical grounding, students are equipped to enter the workforce confidently at any stage of their education.",
  },
];

function KeyPillars() {
  return (
    <section className="pillars__section" id="pillars">
      <p className="section__title">Key Pillars of the FUSN Vision</p>

      <div className="pillars__section-wrapper">
        <div className="pillars__card-wrapper">
          {pillarsData.map((pillar, index) => (
            <article key={index} className="pillars__card">
              <div className="pillars__card-content">
                <div className="pillars__card-icon-wrapper">
                  <i className={`${pillar.icon} pillars__card-icon`}></i>
                </div>
                <div className="pillars__card-data">
                  <p className="pillars__card-heading">{pillar.heading}</p>
                  <p className="pillars__card-text">{pillar.text}</p>
                </div>
              </div>
            </article>
          ))}
        </div>
      </div>
    </section>
  );
}

export default KeyPillars;
