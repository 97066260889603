// import Sidebar from "../../../components/blogs/BlogsSidebar";
import Sidebar from "../../../components/blog/blogsidebar"
import { useEffect, useState } from "react";
import axios from "axios";
// import { WP_BASE_API_URL } from "../../../api";
import { WP_BASE_API_URL } from "../../../utils/constants";
// import Head from "next/head";
// import BlogItem from "../../../components/blogs/BlogItem";
import BlogItem from "../../../components/blog/blogItem" 
import React from "react";

const PostDetail = ({ data, categories, category, posts }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [relateddPost, setRelatedPost] = useState(posts);



  console.log("data", data)
  console.log("categories", categories)
  console.log("category", category)
  console.log("posts", posts)
  useEffect(() => {
    setRelatedPost(posts);
  }, [posts]);

  const handleSearchpost = (term_) => {
    setSearchTerm(term_);
  };

  return (
    <main>
      {/* <Head>
        <title>{data?.[0]["title"]["rendered"]} </title>
        <meta
          name="description"
          content={`${data?.[0]["title"]["rendered"]} - ${category} category`}
        />
      </Head> */}

      <div className="bg-skin-gray font-default min-h-[500px] px-8 py-4 flex justify-center ">
        <div className="container max-w-[1440px] ">
          <div className="flex flex-col-reverse lg:h-screen lg:flex-row gap-4">
            <div className="w-full bg-white lg:w-3/4 max-h-screen overflow-y-auto">
              {data
                ?.filter((post) => {
                  if (searchTerm == "") {
                    return post;
                  } else if (
                    post.title.rendered
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
                  ) {
                    return post;
                  }
                })
                .map((post, index) => {
                  var today = new Date(post["date"]).toLocaleDateString();
                  return (
                    <div key={index}>
                      <img
                        className="w-full  object-cover object-center"
                        src={
                          post["_embedded"]["wp:featuredmedia"]?.[0][
                            "source_url"
                          ]
                        }
                        alt="blog"
                      />
                      <div className="pt-6 px-4 md:px-10 blog-details pb-5">
                        <h1 className="title-font text-lg font-medium text-gray-900 mb-3">
                          {post["title"]["rendered"]}
                        </h1>
                        <div className="text-sm mb-5 text-gray-500 truncate">
                          {post["_embedded"]["author"][0]["name"]}:{today}
                        </div>
                        <div
                          className="text-gray-600"
                          dangerouslySetInnerHTML={{
                            __html: post["content"]["rendered"],
                          }}
                        />
                      </div>
                    </div>
                  );
                })}

              {relateddPost?.length > 0 && (
                <div className="px-4 md:px-10  w-full">
                  <hr />
                  <p className="font-bold text-lg font-default mt-5">
                    Related Post
                  </p>

                  <div className="grid grid-cols-1  md:grid-cols-2 gap-10 my-4  w-full">
                    {relateddPost.slice(0, 3).map((blog) => (
                      <BlogItem
                        key={blog.id}
                        blog={blog}
                        type="related"
                        px="px-3"
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div className="w-full h-full flex flex-col bg-white lg:relative lg:w-1/4 ">
              <div className="lg:sticky lg:top-12">
                <Sidebar
                  category={categories}
                  handleSearchpost={handleSearchpost}
                  selectedCategory={category}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default PostDetail;

export async function getStaticPaths() {
  const getPosts = await axios(
    `${WP_BASE_API_URL}/wp-json/wp/v2/posts?per_page=100`
  );
  const data = getPosts.data;

  const sideCategory = await axios(
    `${WP_BASE_API_URL}/wp-json/wp/v2/categories?per_page=100`
  );
  const category = sideCategory.data;

  const allpost = data.map((item) => {
    const categoryNames = category.filter((item2) => {
      return item.categories.includes(item2?.id);
    });
    return {
      params: { id: String(item?.id), category: categoryNames[0]?.slug },
    };
  });

  return {
    paths: allpost,
    fallback: true, // false or 'blocking'
  };
}

export async function getStaticProps(context) {
  const allpost = context.params.id;
  const category = context.params.category;

  const sideCategory = await axios(
    `${WP_BASE_API_URL}/wp-json/wp/v2/categories?per_page=100`
  );
  const categories_ = sideCategory.data;

  const category_id = categories_.filter((item) => {
    return item?.slug === category;
  })[0]?.id;

  const getPosts = await axios(
    `${WP_BASE_API_URL}/wp-json/wp/v2/posts?_embed&include[]=${allpost}`
  );
  const data = getPosts.data;

  const postCategory = await axios(
    `${WP_BASE_API_URL}/wp-json/wp/v2/posts?_embed&categories=${category_id}`
  );
  const posts = postCategory.data.filter((item) => {
    return String(item.id) !== allpost;
  });

  const categories = categories_.map((item) => {
    return {
      id: item?.id,
      name: item.name,
      link: `/blogs/category/${item?.slug}`,
      slug: item?.slug,
      count: item.count,
    };
  });

  return { props: { data, categories, category, posts }, revalidate: 60 };
}
