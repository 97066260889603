import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import Layout from './pages/layout';
import About_Us from "./pages/about_us";
import FaqPage from "./pages/faq";
import ContactUs from "./components/contact_us/contact_us";
import PrivacyPolicyPage from "./components/Legal_terms/privacy_policy";
import TermsAndConditionsPage from "./components/Legal_terms/terms_and_conditions";
import CookiesPolicyPage from "./components/Legal_terms/cookie_policy";
import Offer from "./components/offer/offer";
import WhyChooseUs from "./components/WhyChooseUs/WhyChooseUs";
import About_Page from "./components/About_Page/about_page";
import Apply_For_Affiliations from "./components/apply_for_affiliations/apply_for_affiliations";
import Services_Page from "./components/Services_Page/services_page";
import Blogs from "./components/Blogs_Pages/blogs_pages";
import PostDetail from "./pages/blogs/[category]/[id]";
import BlogDetail from "./components/blog/dynamicBlog";
import BlogPage from "./components/blog/blogMainPage";
import BlogCategoryMainPage from "./components/blog/blogCategoryMainPage";
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import FUSNPage from "./components/FUSN/FUSN";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

function App() {


  return (
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<Home />}>
              {/* <Route index element={<StudentsListOverview />} /> */}
              <Route index element={<Home />} 
              />
             
            </Route>
            <Route path='about-us' element={<Layout />}>
              <Route index element={<About_Page />} />
              </Route>
              <Route path='services' element={<Layout />}>
              <Route index element={<Services_Page />} />
              </Route>
              <Route path='why-choose-us' element={<Layout />}>
              <Route index element={<WhyChooseUs />} />
              </Route>
              <Route path='faqs' element={<Layout />}>
              {/* <Route path='/faqs' element={<FaqPage />} /> */}
              <Route index element={<FaqPage />} />
              </Route>
              <Route path='contact-us' element={<Layout />}>
             
              <Route index element={<ContactUs />} />
              </Route>
              <Route path='blogs/:category/:id' element={<Layout />}>
             
              <Route index element={<PostDetail/>} />
              </Route>
            
              <Route path='blogs/categories/:category/:id' element={<Layout />}>
             
              <Route index element={<BlogCategoryMainPage/>} />
              </Route>
                  <Route path='blogss/:postId'  element={<Layout />} >
          <Route index element={<BlogPage />} />
        </Route>

              <Route path='affiliations' element={<Layout />}>
             
              <Route index element={<Apply_For_Affiliations />} />
              </Route>

              <Route path='fusn' element={<Layout />}>
             
             <Route index element={<FUSNPage />} />
             </Route>
              <Route path='privacy-policy' element={<Layout />}>
             
              <Route index element={<PrivacyPolicyPage />} />
              </Route>
              <Route path='terms_and_conditions' element={<Layout />}>
             
              <Route index element={<TermsAndConditionsPage />} />
              </Route>
              <Route path='cookie-policy' element={<Layout />}>
             
              <Route index element={<CookiesPolicyPage />} />
              </Route>

          </Routes>
        </BrowserRouter>
  );
}

export default App;

