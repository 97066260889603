import axios from "axios";
import { WP_BASE_API_URL } from "../utils/constants";


// interface WPGetPostsByIDDTO {
//   id: number;
// }

// interface WPGetLatestPostsDTO {
//   count: number;
// }


axios.defaults.withCredentials = true;

const getPostsByCategoryId = async (id) => {
  const { data } = await axios.get(
    `${WP_BASE_API_URL}/wp-json/wp/v2/posts?_embed&categories=${id}`
  );
  return data;
};

const getLatestPosts = async (dto) => {
  const { data } = await axios.get(
    `${WP_BASE_API_URL}/wp-json/wp/v2/posts?_embed&per_page=${dto.count}`
  );
  return data;
};
const getPostById = async (id) => {
  const { data } = await axios.get(
    `${WP_BASE_API_URL}/wp-json/wp/v2/posts?_embed&include[]=${id}`
  );
  return data;
};
const getAllCategories = async (id) => {
  const { data } = await axios.get(
    `${WP_BASE_API_URL}/wp-json/wp/v2/categories?per_page=100`
  );
  return data;
};

const wpService = {
  getPostsByCategoryId,
  getLatestPosts,
  getPostById,
  getAllCategories
};

export default wpService;